import React, { Component } from "react";

export default class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="home" id="home">
          <div className="container">
            <div className="row">
              <div className="home-content">
                <h2>Jordi</h2>
                <h1>Software Engineer</h1>
              </div>
            </div>
          </div>
          <div className="continue-arrow">
            <a href="#about" className="btn-scroll">
              <i className="fas fa-chevron-down"></i>
            </a>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
