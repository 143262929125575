import React, { Component } from "react";

export default class NavItem extends Component {
  render() {
    const { link, title } = this.props;
    return (
      <React.Fragment>
        <li className="nav-item">
          <a
            className="nav-link hover-underline-animation"
            href={`#${link}`}
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
          >
            {title}
          </a>
        </li>
      </React.Fragment>
    );
  }
}
