import React, { Component } from "react";
import NavItem from "./NavItem";

const data = [
  { id: 1, link: "home", title: "Home" },
  { id: 2, link: "about", title: "About" },
  { id: 3, link: "projects", title: "Projects" },
  { id: 4, link: "skills", title: "Skills" },
  { id: 5, link: "tools", title: "Tools" },
  { id: 6, link: "contact", title: "Contact" },
];
export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { isActive: false };
  }

  toggleNav = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };
  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href="#home">
              Jordi
            </a>

            <button
              onClick={this.toggleNav}
              className={`navbar-toggler hamburger hamburger--spin ${
                this.state.isActive ? "is-active" : ""
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                {data.map((data, index) => (
                  <NavItem
                    key={index}
                    link={data.link}
                    title={data.title}
                  ></NavItem>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
