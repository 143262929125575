import React, { Component } from "react";
import SkillsItem from "./SkillsItem";

const data = [
	{ id: 1, icon: "csharp", skill: "C#" },
	{ id: 2, icon: "dot-net", skill: ".NET" },
	{ id: 3, icon: "python", skill: "python" },
	{ id: 4, icon: "django", skill: "Django" },
	{ id: 5, icon: "vuejs", skill: "Vue" },
	{ id: 6, icon: "java", skill: "Java" },
	{ id: 7, icon: "mysql", skill: "MySQL" },
	{ id: 8, icon: "mongodb", skill: "MongoDB" },
	{ id: 9, icon: "postgresql", skill: "PostgreSQL" },
	{ id: 10, icon: "javascript", skill: "JavaScript" },
	{ id: 11, icon: "html5", skill: "HTML5" },
	{ id: 12, icon: "css3", skill: "CSS3" },
	{ id: 13, icon: "bootstrap", skill: "Bootstrap 5" },
	{ id: 14, icon: "nodejs", skill: "NodeJS" },
	{ id: 15, icon: "angularjs", skill: "Angular" },
	{ id: 16, icon: "android", skill: "Android" },
];

export default class Skills extends Component {
	render() {
		return (
			<React.Fragment>
				<section className="skills" id="skills">
					<div className="container">
						<div className="section-header">
							<h2>Skills</h2>
						</div>
						<div className="skills-grid row">
							{data.map((data, index) => (
								<SkillsItem
									key={index}
									icon={data.icon}
									skill={data.skill}
									delay={index * 100}></SkillsItem>
							))}
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}
