import React, { Component } from "react";

export default class Loading extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="loading">
          <div className="cube">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
