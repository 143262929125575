import React, { Component } from "react";

export default class Projects extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="projects" id="projects">
            <h2>Projects coming soon...</h2>
            {/* <div className="section-header">
              <h2>Projects</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card card-cover maps-project">
                  <div className="card-gradient">
                    <div className="card-content">
                      <h2>
                        Fade in
                        <br />
                        Bottom Text
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card card-cover maps-project">
                  <div className="card-gradient">
                    <div className="card-content">
                      <h2>
                        Fade in
                        <br />
                        Bottom Text
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card card-cover maps-project">
                  <div className="card-gradient">
                    <div className="card-content">
                      <h2>
                        Fade in
                        <br />
                        Bottom Text
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </section>
      </React.Fragment>
    );
  }
}
