import React from "react";
import ReactDOM from "react-dom";

import "./assets/styles/main.sass";
import "./assets/styles/loader.sass";
import "./assets/styles/hamburger.sass";
import "./assets/styles/navbar.sass";
import "./assets/styles/home.sass";
import "./assets/styles/about.sass";
import "./assets/styles/experience.sass";
import "./assets/styles/timeline.sass";
import "./assets/styles/skills.sass";
import "./assets/styles/tools.sass";
import "./assets/styles/projects.sass";
import "./assets/styles/contact.sass";

import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Experience from "./components/Experience";
import Skills from "./components/Skills";
import Tools from "./components/Tools";
import Loading from "./components/Loading";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Loading />
    <Navbar />
    <Home />
    <About />
    <Experience />
    <Projects />
    <Skills />
    <Tools />
    <Contact />
    <Footer />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
