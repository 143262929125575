import React, { Component } from "react";
import TimelineItem from "./TimelineItem";

const uniDescription = (
  <React.Fragment>
    <strong>Major:</strong>
    <ul>
      <li>Software Engineering</li>
    </ul>
    <strong>Minors:</strong>
    <ul>
      <li>Mobile Developement</li>
      <li>Big Data</li>
    </ul>
  </React.Fragment>
);

const data = [
  {
    title: {
      general: "Gradute ",
      strong: "@ Amsterdam University of Applied Sciences",
    },
    date: "September 2016 - February 2021",
    description: uniDescription,
  },
  {
    title: {
      general: "Full Stack Developer Intern ",
      strong: "@ Ordina",
    },
    date: "October 2018 - February 2019",
    description:
      "I was a developer within Ordina their Java department. During my internship I worked on a number of internal projects. I mainly focused on the front-end. During my internship I mainly worked with the following techniques: StencilJS, NodeJS and Angular.",
  },
  {
    title: {
      general: "Backend Developer Intern ",
      strong: "@ Elements Interactive",
    },
    date: "February 2020 - August 2020",
    description:
      "At Elements I helped with development on one of their platforms. This was mostly in Python with Django as their Web framework in combination with PostgreSQL. In order to graduate I was required to write a thesis at the company too. The thesis was about how we were able to automate and optimize the platform with the use of machine learning.",
  },
  {
    title: {
      general: ".NET Developer ",
      strong: "@ Arlanet",
    },
    date: "June 2021 - Current",
    description:
      "At Arlanet I am creating and maintaining software solutions using .NET framework and Umbraco CMS, focusing on meeting client needs and industry standards through collaborative development efforts.",
  },
];
export default class Experience extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="experience">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="experience-header">
                  <h2>Experience</h2>
                </div>
                <ul className="timeline">
                  {data.map((data, index) => (
                    <TimelineItem
                      key={index}
                      title={data.title}
                      date={data.date}
                      description={data.description}
                      delay={index * 200}
                    ></TimelineItem>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
