import React, { Component } from "react";

export default class ToolsItem extends Component {
  render() {
    const { icon, skill, delay } = this.props;
    return (
      <React.Fragment>
        <div className="col-lg-4 col-6">
          <div
            className="item"
            data-aos="zoom-out-up"
            data-aos-once="true"
            data-aos-delay={delay}
            data-aos-anchor=".tools-grid"
          >
            <span className="icon">
              <i
                className={`devicon-${icon}-${
                  icon === "npm" ? "original-wordmark" : "plain"
                }`}
              ></i>
            </span>
            <h6>{skill}</h6>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
