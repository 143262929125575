import React, { Component } from "react";

export default class Contact extends Component {
	render() {
		return (
			<React.Fragment>
				<section className="contact" id="contact">
					<div
						className="container"
						data-aos="zoom-in"
						data-aos-once="true">
						<div className="row">
							<div className="col-lg-6">
								<div className="contact-header">
									<h1>Leave a message</h1>
								</div>
								<div className="contact-form">
									<form
										name="contact"
										method="post"
										action=""
										netlify
										netlify-honeypot="bot-field">
										<div className="row">
											<input
												type="hidden"
												name="form-name"
												value="contact"
											/>
											<div className="col-md-5">
												<input
													className="form-control"
													name="name"
													id="contact-name"
													type="text"
													placeholder="Your Name:"></input>
											</div>
											<div className="col-md-7">
												<input
													className="form-control"
													name="email"
													id="contact-email"
													type="email"
													placeholder="Email:"></input>
											</div>
											<div className="col-md-12">
												<textarea
													className="form-control"
													name="message"
													id="message"
													cols="30"
													rows="5"
													placeholder="Your message:"></textarea>
											</div>
											<div className="col-12 button-body">
												<button
													type="submit"
													className="btn">
													Submit
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}
