import React, { Component } from "react";
import astronaut from "../assets/images/astronaut.gif";

export default class About extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="about" id="about">
          <div className="container">
            <div className="row about-content">
              <div
                className="col-lg-5"
                data-aos-once="true"
                data-aos="fade-down-right"
              >
                <div className="hero-image">
                  <img
                    className="profile-picture"
                    src={astronaut}
                    alt="Noooo"
                  ></img>
                </div>
              </div>
              <div
                className="col-lg-7"
                data-aos-once="true"
                data-aos="fade-down-left"
              >
                <h2 className="about-title">About me</h2>
                <p>
                  Hi there! My name is Jordi and I am a Software Engineer with a
                  passion for developing web applications and other creative software solutions.
                </p>
                <p>
                  As a Software Engineer graduate and being a fulltime developer for 3 years now,
                  I have gained experience in a wide variety of programming
                  languages such as C#, Python, SQL, Vue and many more!
                </p>
                <p>
                  I've tackled a diverse array of projects, spanning from developing webshops and content websites 
                  to building statistics dashboards and training machine learning models. I have worked in agile software development
                  teams, where we applied scrum throughout all of our projects.
                </p>
                <p>
                  I am currently working as a .NET Developer focusing on sharpening up my skills to develop
                  software solutions in even more efficient and creative ways.
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
