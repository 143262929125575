import React, { Component } from "react";

export default class TimelineItem extends Component {
  render() {
    const { title, date, delay, description } = this.props;
    return (
      <React.Fragment>
        <li className="timeline-item bg-white rounded ms-3 p-4 shadow">
          <div className="timeline-arrow"></div>
          <div
            className="timeline-content"
            data-aos="fade-left"
            data-aos-once="true"
            data-aos-delay={delay}
            data-aos-anchor=".timeline"
          >
            <h5 className="mb-0">
              {title.general}
              <strong>{title.strong}</strong>
            </h5>
            <span className="small text-gray">
              <i className="far fa-clock me-1"></i>
              {date}
            </span>
            <div className="text-small mt-2 font-weight-light">
              {description}
            </div>
          </div>
        </li>
      </React.Fragment>
    );
  }
}
