import React, { Component } from "react";
import ToolsItem from "./ToolsItem";

const data = [
	{ id: 1, icon: "git", skill: "Git" },
	{ id: 2, icon: "github", skill: "GitHub" },
	{ id: 3, icon: "azure", skill: "Azure" },
	{ id: 4, icon: "vscode", skill: "Visual Studio Code" },
	{ id: 5, icon: "visualstudio", skill: "Visual Studio" },
	{ id: 6, icon: "npm", skill: "NPM" },
	{ id: 7, icon: "yarn", skill: "Yarn" },
	{ id: 8, icon: "pycharm", skill: "PyCharm" },
	{ id: 9, icon: "intellij", skill: "IntelliJ" },
];

export default class Tools extends Component {
	render() {
		return (
			<React.Fragment>
				<section className="tools" id="tools">
					<div className="container">
						<div className="section-header">
							<h2>Tools</h2>
						</div>
						<div className="tools-grid row">
							{data.map((data, index) => (
								<ToolsItem
									key={index}
									icon={data.icon}
									skill={data.skill}
									delay={index * 100}></ToolsItem>
							))}
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}
