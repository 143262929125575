import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <span>
           Designed & Built by Jordi.
          </span>
        </footer>
      </React.Fragment>
    );
  }
}
