import React, { Component } from "react";

export default class SkillsItem extends Component {
  render() {
    const { icon, skill, delay } = this.props;
    return (
      <React.Fragment>
        <div className="col-lg-3 col-md-4 col-6">
          <div
            className="item"
            data-aos="zoom-out-up"
            data-aos-once="true"
            data-aos-delay={delay}
            data-aos-anchor=".skills-grid"
          >
            <span className="icon">
              <i className={`devicon-${icon}-plain`}></i>
            </span>
            <h6>{skill}</h6>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
